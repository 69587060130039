<i18n>
ru:
  title: '{product} выгоднее в комбо:'
ua:
  title: '{product} вигідніший у комбо:'
us:
  title: '{product} is more profitable in a combo:'
</i18n>

<template>
  <div
    v-if="productsListContainingProduct && productsListContainingProduct.length > 0"
    class="v-products-containing-product"
  >
    <span class="v-products-containing-product__title v-mb-xs">
      {{ translate('productsContainingProduct.title', { product: productName }) }}
    </span>
    <div class="v-containing-products-wrapper">
      <arora-swiper-slider
        class-name="v-pm-shadow"
        :items="productsListContainingProduct"
        :space-between="7"
        max-items="auto"
        pagination-type="none"
      >
        <template #item="item: ProductInList">
          <div
            class="v-containing-products-item"
            @click="() => handleOpenProductPopup(item)"
          >
            <arora-nuxt-image
              :key="item.ID"
              disable-lazy
              :alt="item.Name"
              :image="
                imageExists(item.CombineMobileImage)
                  ? item.CombineMobileImage
                  : item.NormalImage
              "
              :image-type="
                imageExists(item.CombineMobileImage)
                  ? 'CombineMobile'
                  : 'ProductNormal'
              "
            />
            <div
              class="v-containing-products-item-title v-title"
              v-html="sanitize(item.Name)"
            />
            <div class="v-containing-products-item-description">
              <template v-if="item.ShowTextInPriceButton">
                <span
                  class="v-mr-xs"
                  v-html="translate('productCard.showTextInPriceButton')"
                />
              </template>
              <common-currency
                v-if="item.Price > 0"
                :amount="item.Price"
              />
              <div
                class="v-containing-products-item-description-button"
                data-test-id="containing-products-item-button"
              >
                <ui-icon
                  disable-hover
                  name="add"
                  color="mono1000"
                  :size="24"
                />
              </div>
            </div>
          </div>
        </template>
      </arora-swiper-slider>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductInList } from '~types/menuStore'

import type { GUID } from '@arora/common'

const { productId } = defineProps<{
  productId: GUID
  productName: string
}>()

const menuStore = useMenuStore()
const popupStore = usePopupStore()
const { sanitize, translate } = useI18nSanitized()
const { imageExists } = useImageInfo()
const { openProductPopup } = useProduct()
const productsListContainingProduct = ref<ProductInList[] | null>([])

const hasActivePopupLocal = popupStore.hasActivePopup
const handleOpenProductPopup = async (product: ProductInList): Promise<void> => {
  if (hasActivePopupLocal) {
    await popupStore.closePopup()
  }
  await openProductPopup(product)
}

onMounted(async () => {
  if (import.meta.client)
    productsListContainingProduct.value =
      await menuStore.getProductsContainingById(productId)
})
</script>

<style lang="scss">
@use 'assets/variables';

.v-products-containing-product {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &__title {
    font-weight: 700;
    font-size: 1.3rem;
  }

  .v-containing-products-wrapper {
    margin-bottom: 10px;

    .swiper-slide {
      width: fit-content;
      height: initial;
    }

    .v-containing-products-item {
      display: flex;
      flex-direction: column;
      padding: 10px;
      height: 100%;
      border-radius: variables.$BorderRadius;
      box-shadow: variables.$CardShadow;
      border: 1px solid variables.$BorderColor;
      position: relative;
      cursor: pointer;
      max-width: 140px;

      &-title {
        text-align: center;
        margin-bottom: 10px;
        font-size: 1rem;
      }

      &-description {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
        font-weight: 700;

        .v-currency-wrapper {
          i {
            font-weight: inherit;
            font-size: 0.9rem;
          }
        }

        &-button {
          margin-left: auto;
          background: variables.$PrimaryBackgroundColor;
          color: variables.$PrimaryColor;

          width: 1.7rem;
          height: 1.7rem;

          border-radius: 50%;

          padding: 0.35rem;

          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
}
</style>
